import { Component } from '@angular/core';
import { Geofence } from '@ov-suite/models-yard';
import { SessionStorage } from '@ov-suite/helpers-angular';

@Component({
  selector: 'ov-suite-geofence-list',
  templateUrl: './geofence-list.component.html',
  styleUrls: ['./geofence-list.component.scss'],
})
export class GeofenceListComponent {
  domainId = SessionStorage.getSelectedDomain();

  // Class - Required
  formClass = Geofence;

  // filter = { domainId: [this.domainId]}
  filter = {}

  constructor() {}
}
