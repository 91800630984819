<div class="modal-header">
    <h6 class="modal-title">Select Users</h6>
  </div>
  <div class="modal-body modal-lg">
    <div class="flex-end">
      <input type="text" class="form-control" placeholder="Search..." [(ngModel)]="searchValue" />
    </div>
    <table class="table table-striped" aria-describedby="View Users">
      <thead>
      <tr>
        <th scope="col">First Name</th>
        <th scope="col">Last Name</th>
        <th scope="col">Selected</th>
      </tr>
      </thead>
      <tbody>
      <tr  *ngFor="let item of allUsers | filter: searchValue">
        <td class="w-50">
          <div class="col-md-4">
            <span>{{item.firstName}}</span>
          </div>
        </td>
        <td class="w-50">
          <div class="col-md-4">
            <span>{{item.lastName}}</span>
          </div>
        </td>
        <td class="w-50">
          <div class="col-md-4">
            <input type="checkbox" (change)="onToggleSelect(item)" [checked]="userIsSelected(item)" />
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
