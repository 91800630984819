import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import { Router } from '@angular/router';
import { startCase } from 'lodash';
import { QueueModel, QueueItem, QueueGroup } from '@ov-suite/models-yard';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QueuesService } from '../../../services/queues/queues.service';
import { TableTopTabMenuItem } from '../../../components/table-top-tab-menu/table-top-tab-menu.model';
import { QueueConfigModalComponent } from '../queue-config-modal/queue-config-modal.component';

@Component({
  selector: 'ov-suite-queues-list',
  templateUrl: './queues-list.component.html',
  styleUrls: ['./queues-list.component.scss'],
})
export class QueuesListComponent implements OnInit {
  formClass = QueueItem;

  queueGroups: QueueGroup[];

  queues: QueueModel[] = [];

  queuesForActiveGroup: QueueModel[] = [];

  /**
   * Tabs at top of Screen
   */
  tabs: TableTopTabMenuItem[] = [];

  tableTabs: TableTopTabMenuItem[] = [];

  activeGroupTab: TableTopTabMenuItem;

  activeGroupId: number;

  activeTabId: number;

  queueItems: QueueItem[] = [];

  filter: Record<string, unknown> = {
    id: [0],
  };

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    public readonly ovAutoService: OvAutoService,
    public queuesService: QueuesService,
    private readonly ngZone: NgZone,
    private readonly route: Router,
    private readonly modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    // const url = this.route.url.split('?')[0];
    // const key = url.slice(1).split('/')[0];
    // const key = url.slice(this.nthIndex(url, '/', 1) + 1, this.nthIndex(url, '/', 2));
    this.fetchQueues().then(() => {
      // const activeTab = this.queues.find(queue => queue.key.toLowerCase().includes(key.toLowerCase()));
      // if (activeTab) {
      //   this.activeTabId = activeTab.id;
      //   this.filter = {
      //     'queue.id': [activeTab.id],
      //   };
      // }
    });
  }

  async fetchQueues() {
    await this.ovAutoService
      .list({
        entity: QueueModel,
        keys: [
          'id',
          'capacity',
          'name',
          'key',
          'priorityList',
          'priorityList.id',
          'priorityList.priority',
          'priorityList.priorityGroup',
          'limits',
          'limits.id',
          'limits.priorityGroup',
          'limits.priority',
          'limits.limit',
        ],
      })
      .then(queueResponse => {
        this.queues = queueResponse.data;
        this.tabs = this.getTabs();
        if (this.queues.length) {
          this.activeTabId = this.queues[0].id;
          this.filter = {
            'queue.id': [this.activeTabId],
          };
        }
      });
  }

  async fetchQueueItemsForActiveTab() {
    await this.ovAutoService
      .list({
        entity: QueueItem,
        query: {
          'queue.id': [this.activeTabId],
        },
      })
      .then(response => {
        console.log({ response });
        this.queueItems = response.data;
      });
  }

  getTabs() {
    const tabArr = [];
    this.queues.forEach(queue => {
      tabArr.push({ title: startCase(queue.name), path: queue.key, key: queue.id });
    });

    return tabArr;
  }

  // getQueuesForActiveGroup() {
  //   this.queuesForActiveGroup = this.queues.filter(obj => obj.group.id === this.activeGroupId);
  //   const keys = Object.keys(this.queuesForActiveGroup);
  //   const tabArr = [];
  //   keys.forEach((key, index) => {
  //     const title = startCase(this.queuesForActiveGroup[index].name);
  //     const path = this.queuesForActiveGroup[index].key;
  //     tabArr.push({ title, path, key: path });
  //   });
  //
  //   return tabArr;
  // }

  onTabClick(id: string): void {
    const tab = this.tabs.find(t => t.key === id);
    if (tab) {
      this.activeTabId = Number(id);
      this.filter = {
        'queue.id': [id],
      };
    }
  }

  public async openConfigModal(): Promise<void> {
    const modalRef = this.modalService.open(QueueConfigModalComponent, { size: 'xl' });
    modalRef.componentInstance.queue = this.queues.find(q => q.id === this.activeTabId);
    modalRef.componentInstance.dismissModal = modalRef;
    // modalRef.componentInstance.selectedUsers = this.geofenceAlertUsers.filter(
    //   item => item.geofenceAlert && item.geofenceAlert.id === alert.id,
    // );
    // modalRef.componentInstance.alert = alert;
    // modalRef.componentInstance.selectToggle.subscribe(item => this.selectToggleUser(item));
  }
}
