import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbModalConfig, NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserRep } from '@ov-suite/models-idm';
import { GeofenceAlert, GeofenceAlertUser } from '@ov-suite/models-yard';
import { FilterPipe } from '../../pipes/filter.pipe';

@Component({
  templateUrl: './modal.component.html',
  providers: [NgbModalConfig, NgbModal, FilterPipe],
})
export class ModalComponent {
  searchValue = '';
  @Input() allUsers: UserRep[] = [];
  @Input() selectedUsers: GeofenceAlertUser[] = [];
  @Input() alert: GeofenceAlert;
  @Output() selectToggle = new EventEmitter<{ user: UserRep; alert: GeofenceAlert }>();


  constructor(config: NgbModalConfig, public activeModal: NgbActiveModal) {
    // customize default values of modals used by this component tree
    config.backdrop = false;
    config.keyboard = false;
    config.size = 'lg';
    config.centered = true;
  }

  onToggleSelect(user: UserRep): void {
    const emitValue = {
      user,
      alert: this.alert,
    };
    this.selectToggle.emit(emitValue);
  }

  userIsSelected(user: UserRep): boolean {
    return !!this.selectedUsers.filter(item => {
      return item.user.id === user.id;
    }).length;
  }
}
