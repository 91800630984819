import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import { QueueItem } from '@ov-suite/models-yard';

@Injectable()
export class QueuesService extends AutoService<QueueItem> {
  constructor(private readonly apollo: Apollo) {
    super(
      apollo.use('yardlink'),
      QueueItem,
      'QueueItem',
      'QueueItems',
      'yardlink'
    );
  }
}
