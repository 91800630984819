import { OVField, OVTable, OVEntity, OVSearchable } from '@ov-suite/ov-metadata';
import swal from 'sweetalert2';

@OVEntity({ name: 'QueuePartial' })
export class QueuePartial {
  @OVField({ type: 'number' })
  id: number;

  @OVSearchable()
  @OVField({ type: 'string' })
  name: string;

  @OVSearchable()
  @OVField({ type: 'string' })
  key: string;
}

@OVEntity({ name: 'QueueItem', api: 'yardlink', tableName: 'queue_item' })
@OVTable<QueueItem>([
  {
    key: 'id',
    title: 'Unique ID',
    type: 'number',
  },
  {
    key: 'name',
    title: 'Registration',
    type: 'string',
  },

  {
    key: 'identifier',
    title: 'Identifier',
    type: 'string',
  },
  {
    key: 'priorityLevel',
    title: 'Priority',
    type: 'number',
  },
  {
    title: 'Actions',
    type: 'buttons',
    hideColumnKey: 'actions',
    disableSorting: true,
    disableFiltering: true,
    keys: [],
    buttons: [
      {
        classes: 'btn btn-success btn-sm',
        icon: 'fa fa-angle-double-up text-white',
        action: async (item, { apiService }) => {
          item.priorityLevel += 1;
          await apiService.update({ entity: QueueItem, item: { id: item.id, priority: item.priorityLevel } }).then(updatedItem => {
            console.log({ updatedItem });
            swal
              .fire({
                title: 'Approved',
                type: 'success',
                confirmButtonText: 'Done',
              })
              .then(items => console.log({ items }))
              .catch(error => console.log({ error }));
          });
        },
      },
      {
        classes: 'btn btn-danger btn-sm',
        icon: 'fa fa-angle-double-down text-white',
        action: async (item, { apiService }) => {
          item.priorityLevel -= 1;
          await apiService.update({ entity: QueueItem, item: { id: item.id, priorityLevel: item.priorityLevel } }).then(() => {
            swal
              .fire({
                title: 'Approved',
                type: 'success',
                confirmButtonText: 'Done',
              })
              .then(items => console.log({ items }))
              .catch(error => console.log({ error }));
          });
        },
      },
    ],
  },
])
export class QueueItem {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    required: true,
  })
  id: number;

  @OVField({
    type: 'string',
    title: 'Name',
    placeholder: 'Required',
    tooltip: 'Name',
    required: true,
  })
  name: string;

  @OVField({
    type: 'string',
    title: 'Identifier',
    placeholder: 'Required',
    tooltip: 'Identifier',
    required: true,
  })
  identifier: string;

  @OVField({
    type: 'string',
    title: 'Priority Level',
    placeholder: 'Key',
    tooltip: 'Priority Level',
    required: true,
  })
  priorityLevel: number;

  domainId: number;
}
