import { OVField, OVTable, OVEntity } from '@ov-suite/ov-metadata';

@OVEntity({ name: 'QueueGroup', api: 'yardlink', tableName: 'queue_group' })
@OVTable<QueueGroup>([
  {
    key: 'id',
    title: 'Unique ID',
    type: 'number',
  },
  {
    key: 'name',
    title: 'Name',
    type: 'string',
  },
  {
    key: 'key',
    title: 'Key',
    type: 'string',
  },
  {
    key: 'capacity',
    title: 'Key',
    type: 'number',
  },
])

export class QueueGroup {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVField({
    type: 'string',
    title: 'Name',
    placeholder: 'Required',
    tooltip: 'Name',
    required: true,
  })
  name: string;

  @OVField({
    type: 'string',
    title: 'Key',
    placeholder: 'Required',
    tooltip: 'Key',
    required: true,
  })
  key: string;

  domainId: number;

  @OVField({
    type: 'string',
    title: 'Capacity',
    placeholder: 'Key',
    tooltip: 'Key',
    required: true,
  })
  capacity: number;

}
