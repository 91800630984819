<div class="main-content">
  <div class="main-container">
  <ov-suite-hierarchy-table
    [title]="'Geofence Setup'"
    [formClass]="formClass"
    [excludeColumns]="1"
    [showFiller]="false"
    [filter]="filter"
  >
  </ov-suite-hierarchy-table>
</div>
</div>
