import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { ExitEntryEvent } from '@ov-suite/models-yard';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: ExitEntryEvent,
      api: 'yardlink',
      createTitle: 'Entry Event',
      route: ['exit-entry-event'],
      tableTitle: 'Entry Events',
      hideAdd: true,
      hideImport: true,
      hideEditDelete: true,
      hideColumns: ['geofence', 'entryUser', 'exitUser'],
      showFiller: false,
      defaultOrderDirection: 'DESC',
    }),
  ],
})
export class ExitEntryEventModule {}
