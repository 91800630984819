<div class="main-content">
  <ov-suite-table-top-tab-menu [tabs]="tabs" (tabClick)="onTabClick($event)"> </ov-suite-table-top-tab-menu>
  <div class="main-container">
    <div class='d-flex justify-content-end'>
      <button class="btn btn-primary mx-2" (click)="openConfigModal()">Manage Queue Capacity</button>
    </div>

    <ov-suite-hierarchy-table
      [title]="'Queue Items'"
      [formClass]="formClass"
      [ovAutoService]="ovAutoService"
      [hasBulkUpload]="false"
      [showTopBar]="false"
      [selectableRows]="false"
      [editableRows]="true"
      [hideAddButton]="true"
      [hasEditPermission]="false"
      [hasDeletePermission]="false"
      [filter]='filter'
    >
    </ov-suite-hierarchy-table>
  </div>
</div>
