import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Geofence, GeofenceAlert, GeofenceAlertUser } from '@ov-suite/models-yard';
import {
  circle,
  featureGroup,
  FeatureGroup,
  latLng,
  LatLngBounds,
  Map,
  MapOptions,
  polygon,
  tileLayer,
  DrawEvents,
  control,
  Layer,
} from 'leaflet';
import * as Leaflet from 'leaflet';
import { UserRep } from '@ov-suite/models-idm';
import { OvAutoService, OvAutoServiceMultipleMutationParams } from '@ov-suite/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionStorage } from '@ov-suite/helpers-angular';
import { Domain } from '@ov-suite/models-admin';
import { ModalComponent } from '../../../shared/components/modal/modal.component';
import { MapMarkers } from '../../live-map/dashboard/live-map-dashboard.constants';

@Component({
  selector: 'ov-suite-geofence',
  templateUrl: './geofence-add-or-edit.component.html',
  styleUrls: ['./geofence-add-or-edit.component.scss'],
})
export class GeofenceAddOrEditComponent implements OnInit {
  geofenceId: number = null;

  // domainId: string = SessionStorage.getSelectedDomain();

  errors = {
    geofenceName: false,
    geofenceDescription: false,
  };

  geofence: Geofence = new Geofence();

  otherGeofences: Geofence[] = [];

  otherLayers: Layer[] = [];

  geofenceAlerts: GeofenceAlert[] = [];

  geofenceAlertUsers: GeofenceAlertUser[] = [];

  users: UserRep[] = [];

  duration = 20;

  map: Map;

  bounds: LatLngBounds = null;

  markers: MapMarkers[] = [];

  domainCoordinates: [number, number];

  selectedGeofence: Geofence;

  // Leaflet Options
  options: MapOptions = {
    layers: [
      tileLayer('http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
        maxZoom: 21,
        attribution: '...',
        minZoom: 4,
      }),
    ],
    zoom: 16,
    center: latLng(-25.516273, 28.184095),
  };

  layersControl = {
    baseLayers: {
      'Open Street Map': tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' }),
      'Open Cycle Map': tileLayer('http://{s}.tile.opencyclemap.org/cycle/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' }),
    },
    overlays: {
      'Big Circle': circle([46.95, -122], { radius: 5000 }),
      'Big Square': polygon([
        [46.8, -121.55],
        [46.9, -121.55],
        [46.9, -121.7],
        [46.8, -121.7],
      ]),
    },
  };

  public drawnItems: FeatureGroup = featureGroup();

  drawOptions = {
    edit: {
      featureGroup: this.drawnItems,
      removeAllLayers: false,
      edit: false,
    },
    draw: {
      rectangle: false,
      circlemarker: false,
    },
  };

  constructor(
    public readonly ovAutoService: OvAutoService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly modalService: NgbModal,
  ) {
    ovAutoService.get({ entity: Domain, id: Number(SessionStorage.getSelectedDomain()) }).then((domain: Domain) => {
      if (domain?.map?.latitude && domain?.map?.longitude) {
        this.domainCoordinates = [domain.map.latitude, domain.map.longitude];
        this.map.panTo(this.domainCoordinates);
      } else {
        // TODO DEFAULT TO MORE GENERALISED AREA IN SOUTH-AFRICA
        // DEFAULT TO CLAYVILLE (Currently Clover Specific)
        this.map.panTo([-25.96854, 28.226859]);
      }
    });
  }

  public ngOnInit() {
    this.route.queryParamMap.subscribe(routeData => {
      this.geofenceId = +routeData.get('id');
      Leaflet.EditToolbar.Delete.include({
        removeAllLayers: false,
      });
      this.ovAutoService.get({ entity: Geofence, id: this.geofenceId }).then(geofence => {
        const that = this;
        this.geofence = geofence;
        Leaflet.geoJSON(JSON.parse(geofence.geojson), {
          onEachFeature(feature, layer) {
            // @ts-ignore
            layer.setStyle({ fillColor: '#adadad', fillOpacity: 0.5, opacity: 1 });
            that.drawnItems.addLayer(layer);
            // @ts-ignore
            layer.on('click', e => {
              e.target.editing.enable();
            });
          },
        });
      });

      // get existing geofences and add to map
      this.ovAutoService
        .list({
          entity: Geofence,
        })
        .then(geofences => {
          const that = this;
          this.otherGeofences = geofences.data;

          this.otherGeofences
            .filter(geofenceItem => geofenceItem.id !== this.geofence.id)
            .forEach(geofenceItem => {
              Leaflet.geoJSON(JSON.parse(geofenceItem.geojson), {
                onEachFeature(feature, layer) {
                  // keep track of existing geofences so we don't add them to current geofence when saving drawnitems
                  layer['existing'] = true;
                  // @ts-ignore
                  layer.setStyle({ color: 'red', fillColor: '#adadad', fillOpacity: 0.5, opacity: 1 });
                  that.drawnItems.addLayer(layer);
                },
              });
            });
        });
    });

    this.fetchData();
  }

  private fetchData() {
    this.ovAutoService
      .list({ entity: GeofenceAlert, filter: { geofenceId: [this.geofenceId ? this.geofenceId : ''], active: ['true'] } })
      .then(alerts => {
        this.geofenceAlerts = alerts.data;
      });
    this.ovAutoService.list({ entity: GeofenceAlertUser, filter: { active: ['true'] } }).then(users => {
      this.geofenceAlertUsers = users.data;
    });
    this.ovAutoService
      .list({
        entity: UserRep,
        keys: ['id', 'firstName', 'lastName', 'domains'],
        orderColumn: 'firstName',
        orderDirection: 'ASC',
      })
      .then(users => {
        this.users = users.data;
      });
  }

  // Fires when map is loaded
  mapReady(map: Map): void {
    this.map = map;
    map.addControl(control.zoom({ position: 'bottomright' }));
  }

  public onDrawCreated(e: DrawEvents.Created) {
    this.drawnItems.addLayer(e.layer);
    console.log('onDrawCreated', { layer: e.layer });
  }

  async createGeofence() {
    const geofenceAlerts: OvAutoServiceMultipleMutationParams = {};
    await this.ovAutoService
      .create({ entity: Geofence, item: this.geofence })
      .then(async item => {
        this.geofenceAlerts.forEach((alertItem, index) => {
          alertItem['geofence']['id'] = item.id;
          const { id, ...rest } = alertItem;
          geofenceAlerts[`geofenceAlert${index}`] = {
            type: 'create',
            entity: GeofenceAlert,
            item: rest,
          };
        });
        this.ovAutoService.multipleMutation(geofenceAlerts).then(createResponse => {
          const geofenceAlertUsers: OvAutoServiceMultipleMutationParams = {};
          this.geofenceAlerts.forEach((alert, idx) => {
            const savedAlert = Object.values(createResponse)[idx];
            alert['id'] = savedAlert.id as number;
            const items = this.geofenceAlertUsers.filter(user => user.geofenceAlert.id === alert.id);
            items.forEach((val, index) => {
              const { id, ...rest } = val;
              geofenceAlertUsers[`geofenceAlertUser${index}`] = {
                type: 'create',
                entity: GeofenceAlertUser,
                item: rest,
              };
            });
          });
          this.ovAutoService.multipleMutation(geofenceAlertUsers);
        });
      })
      .then(() => this.router.navigate(['/geofence']));
  }

  updateGeofence() {
    const update = { id: this.geofenceId, ...this.geofence };
    this.ovAutoService.update({ entity: Geofence, item: update }).then(() => this.router.navigate(['/geofence']));
  }

  public async saveGeofence() {
    if (!this.geofence.name || !this.geofence.description) {
      this.errors.geofenceName = !this.geofence.name;
      this.errors.geofenceDescription = !this.geofence.description;
      return;
    }
    Object.keys(this.drawnItems['_layers']).forEach(layerKey => {
      if (this.drawnItems['_layers'][layerKey].existing) {
        delete this.drawnItems['_layers'][layerKey];
      }
    });
    this.geofence.geojson = JSON.stringify(this.drawnItems.toGeoJSON());
    // this.geofence.domainId = Number(this.domainId);
    if (!this.geofenceId) {
      this.createGeofence();
    } else {
      this.updateGeofence();
    }
  }

  public onChangeGeofence(geofence: Geofence) {
    this.selectedGeofence = geofence;
    const features = JSON.parse(this.selectedGeofence.geojson).features[0];
    if (features) {
      const coordinates = features.geometry.coordinates[0];
      const [lat, long] = coordinates.reduce((p, c) => [p[0] + c[0], p[1] + c[1]], [0, 0]).map(a => a / coordinates.length);
      this.map.panTo([long, lat]);
      // this.map.setView([long, lat], 17, { duration: 1000 });
    }
  }

  public addUserToAlert(geofenceUpdate: GeofenceAlert, user: UserRep) {
    console.log({ geofenceUpdate })
    geofenceUpdate.geofence = this.geofence;
    this.ovAutoService
      .create({
        entity: GeofenceAlertUser,
        item: {
          geofenceAlert: geofenceUpdate,
          user,
        },
      })
      .then(() => {
        this.fetchData();
      });
  }

  /**
   * Geofence Alerts
   */

  public addNewAlert() {
    if (!this.duration) return;
    if (this.geofence.id) {
      this.ovAutoService
        .create({
          entity: GeofenceAlert,
          item: {
            duration: Number(this.duration),
            geofence: this.geofence,
          },
        })
        .then(() => {
          this.fetchData();
        });
    } else {
      const tempId = Math.random();
      this.geofenceAlerts.push({ duration: Number(this.duration), geofence: this.geofence, id: tempId });
    }
    this.duration = null;
  }

  public removeAlertUser(aUser: UserRep, alert: GeofenceAlert) {
    const geofenceAlertUser = this.geofenceAlertUsers.find(item => {
      if (item.geofenceAlert)
        return item.user.id === aUser.id && item.geofenceAlert.id === alert.id;
      return false;
    });
    if (geofenceAlertUser) this.ovAutoService.delete(GeofenceAlertUser, 'yardlink', geofenceAlertUser.id).then(() => this.fetchData());
  }

  public removeAlert(alert: GeofenceAlert) {
    if (window.confirm('Are you sure you want to delete this item?')) {
      if (this.geofenceId) {
        this.ovAutoService.delete(GeofenceAlert, 'yardlink', alert.id).then(() => this.fetchData());
      } else {
        this.geofenceAlerts = this.geofenceAlerts.filter(alertsArrayItem => alertsArrayItem.id !== alert.id);
      }
    }
  }

  checkUserAdded(alert: GeofenceAlert, user: UserRep): GeofenceAlertUser | undefined {
    console.log({ alert, user, alertUsers: this.geofenceAlertUsers })
    return this.geofenceAlertUsers.find(item => {
      if (item.geofenceAlert) {
        return item.geofenceAlert.id === alert.id && item.user.id === user.id
      }
      return false;
    });
  };

  private selectToggleUser(emittedItem: { user: UserRep; alert: GeofenceAlert }) {
    const { user, alert } = emittedItem;
    const alertUser = this.checkUserAdded(alert, user);
    if (this.geofenceId) {
      if (!alertUser) {
        this.addUserToAlert(alert, user);
      } else {
        this.removeAlertUser(user, alert);
      }
    } else if (!alertUser) {
      this.geofenceAlertUsers.push({ user, geofenceAlert: alert, id: this.geofenceAlerts.length + 1 });
    } else {
      this.geofenceAlertUsers = this.geofenceAlertUsers.filter(item => {
        if(item.geofenceAlert)
          return item.user.id !== user.id && item.geofenceAlert.id !== alert.id
        return false;
      });
    }
  }

  public async openUserModal(alert: GeofenceAlert): Promise<void> {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.allUsers = this.users;
    modalRef.componentInstance.selectedUsers = this.geofenceAlertUsers.filter(
      item => item.geofenceAlert && item.geofenceAlert.id === alert.id,
    );
    modalRef.componentInstance.alert = alert;
    modalRef.componentInstance.selectToggle.subscribe(item => this.selectToggleUser(item));
  }

  /**
   * End: Geofence Alerts
   */
}
